;(async function () {
  const togglers = Array.from(
    document.querySelectorAll<HTMLElement>('.top-nav .open')
  )

  for (const toggler of togglers) {
    let el: HTMLElement | null = toggler
    while (el) {
      if (el.classList.contains('toggler')) {
        await new Promise<void>(resolve => {
          el!.dispatchEvent(
            new CustomEvent<{ transitionTime: number }>('expand', {
              detail: { transitionTime: 0 },
            })
          )
          setTimeout(resolve, 0)
        })
      }

      el = el.parentElement
    }
  }
})()
